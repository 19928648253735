<template>
    <b-card title="Branchenindizes" class="mb-3 shadow">
        <div class="table-scrollable">
                <b-table striped hover
                sticky-header="500px"
                :items="sectorindices"
                :fields="fields"
                responsive="sm"
                >
                </b-table>
        </div>
    </b-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import riskService from '../../../../../services/Riskmanagement/RiskService';

export default {
    name: 'sectorindicestab',
    components: {},
    props: [],
    data() {
        return {
            sectorindices: [],
            selectedYear: null,
        }
    },
    created() {
        this.fetchYears();
        this.fetchSectorIndices();
    },
    methods: {
        ...mapActions({
            fetchYears: 'years/fetch',
        }),
        fetchSectorIndices() {
            this.$store.commit('loading/setLoading', true);
                riskService.listSectorIndices()
                    .then(response => {
                        this.sectorindices = response.data;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            years: 'years/years'
        }),
        fields() {
            return [
                //{ key: 'id', sortable: true },
                { key: 'year.year', label: 'Jahr' },
                { key: 'sector.sector_code_nace', label: 'NACE' },
                { key: 'sector.sector_name', label: 'Branche' },
                { key: 'research_report', label:'Forschungsbericht' },
                { key: 'es_supply_chain_act', label: 'EU Supply Chain Act' },
                { key: 'sector_risk_sum', label: 'Risiko Branche' },
                { key: 'notice', label: 'Notiz' },
            ];
        },
    },
}
</script>