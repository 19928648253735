<template><div>
    <b-card title="Risiken unmittelbare Zulieferer" class="mb-3 shadow" v-if="!hasRole('Lieferant')"">
        <b-button v-b-toggle.help_risks pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
        <b-sidebar right id="help_risks" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
            <div class="px-3 py-2">
                <b-list-group>
                    <b-list-group-item>Hier werden die Ergebnisse der konkreten Risikoanalyse in Heatmaps dargestellt.</b-list-group-item>
                    <b-list-group-item>Es gibt verschiedene Perspektiven.</b-list-group-item>
                    <b-list-group-item></b-list-group-item>
                    <b-list-group-item></b-list-group-item>
                </b-list-group>
            </div>
        </b-sidebar>

        <b-row>
            <b-col>
                <b-form-group label="Jahr" class="mb-3" v-slot="{ ariaDescribedby }">
                    <b-form-select v-model="filterYear" :aria-describedby="ariaDescribedby" class="mt-1" @change="showYear">
                        <option v-for="year in years" :key="year.id" :value="year.id">{{ year.year }}</option>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card>

    <b-card class="mb-3 shadow">
        <b-row>
            <b-col sm="auto"><b-link v-b-toggle.byCompany><span class="when-open"><b-icon icon="chevron-down" /></span><span class="when-closed"><b-icon icon="chevron-right" /></span></b-link></b-col>
            <b-col v-b-toggle.byCompany>
                <h4>Nach Gesellschaft</h4>
            </b-col>
        </b-row>
        <b-collapse id="byCompany">
            <b-container>
                <b-row v-if="!filterYear"><b-col>Kein Jahr ausgewählt.</b-col></b-row>
                <b-row v-else-if="!heatmapData.byCompany.length"><b-col>Keine Daten.</b-col></b-row>
                <b-row v-else>
                    <b-col v-for="item in heatmapData.byCompany" v-bind:key="item.user.id">
                        {{ item.user.display_name }}
                        <heatmapchart :chartdata="item.chartdata" :labels="item.labels" />
                    </b-col>
                </b-row>
            </b-container>
        </b-collapse>
    </b-card>

    <b-card class="mb-3 shadow">
        <b-row>
            <b-col sm="auto"><b-link v-b-toggle.byCategory><span class="when-open"><b-icon icon="chevron-down" /></span><span class="when-closed"><b-icon icon="chevron-right" /></span></b-link></b-col>
            <b-col v-b-toggle.byCategory>
                <h4>Nach Kategorie</h4>
            </b-col>
        </b-row>
        <b-collapse id="byCategory">
            <b-container>
                <b-row v-if="!filterYear"><b-col>Kein Jahr ausgewählt.</b-col></b-row>
                <b-row v-else-if="!heatmapData.byCategory.length"><b-col>Keine Daten.</b-col></b-row>
                <b-row v-else>
                    <b-col v-for="item in heatmapData.byCategory" v-bind:key="item.category">
                        {{ item.category }}
                        <heatmapchart :chartdata="item.chartdata" :labels="item.labels" />
                    </b-col>
                </b-row>
            </b-container>
        </b-collapse>
    </b-card>

    <b-card class="mb-3 shadow">
        <b-row>
            <b-col sm="auto"><b-link v-b-toggle.byTotals><span class="when-open"><b-icon icon="chevron-down" /></span><span class="when-closed"><b-icon icon="chevron-right" /></span></b-link></b-col>
            <b-col v-b-toggle.byTotals>
                <h4>Übersicht Brutto- / Nettobewertung</h4>
            </b-col>
        </b-row>
        <b-collapse id="byTotals">
            <b-container>
                <b-row v-if="!filterYear"><b-col>Kein Jahr ausgewählt.</b-col></b-row>
                <b-row v-else-if="loading"><b-col>Laden...</b-col></b-row>
                <b-row v-else>
                    <b-col>
                        Brutto
                        <barchart :chartdata="heatmapData.total_gross.chartdata" :labels="heatmapData.total_gross.labels" />
                    </b-col>
                    <b-col>
                        Netto
                        <barchart :chartdata="heatmapData.total_net.chartdata" :labels="heatmapData.total_net.labels" />
                    </b-col>
                </b-row>
            </b-container>
        </b-collapse>
    </b-card>
</div></template>

<script>
import {mapActions, mapGetters} from 'vuex';
import riskService from '../../../../../services/Riskmanagement/RiskService';
import heatmapchart from './heatmapchart.vue';
import barchart from './barchart.vue';
export default {
    name: 'heatmaptab',
    components: { heatmapchart, barchart },
    props: [],
    data() {
        return {
            filterYear: null,
            heatmapData: { byCompany:[], byCategory:[], total_gross:{}, total_net: {} },
            loading: false,
        }
    },
    created() {
        // we don not put fetchYears here because that should already be called by another tab on the page
        //this.fetchHeatmapData();
    },
    methods: {
        showYear() {
            if (!!this.filterYear) {
                this.fetchHeatmapData();
            } else {
                this.heatmapData = { byCompany:[], byCategory:[], total_gross:{}, total_net: {} };
            }
        },
        fetchHeatmapData() {
            this.loading = true;
            this.$store.commit('loading/setLoading', true);
                riskService.listDSHeatmapData(this.filterYear)
                    .then(response => {
                        this.heatmapData = response;
                        this.$store.commit('loading/setLoading', false);
                        this.loading = false;
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                        this.loading = false;
                    });
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            years: 'years/years'
        }),
    },
}
</script>